import React from 'react';
import AllowedOriginsTable from 'components/iFrameSettings/AllowedOriginsTable';
import 'components/common/AlertOverlay.scss';
import { CCard, CCardBody, CCardHeader, CCol } from '@coreui/react';
import AllowedOriginsButton from 'components/iFrameSettings/AllowedOriginsButton';
import { useIntl } from 'react-intl';
import { CopyBlock, github } from 'react-code-blocks';
import useAllowedOrigins from 'hooks/settings/useAllowedOrigins';
import AlertGraphQLError from 'components/common/AlertGraphQLError';
import settings from 'config/settings';
import s from './AdminsPage.module.scss';

const codeSnippet = `<style>
    .iframe-container {
        position: relative;
        width: 100%;
        overflow: hidden;
        padding-top: 66.66%;
    }
    .responsive-iframe {
        position: absolute;
        inset: 0;
        width: 100%;
        height: 100%;
        border: none;
    }
  </style>
  
  <div class="iframe-container">
      <iframe class="responsive-iframe" src="${settings.iframeUrl}" name="Dummy" title="MeinBau | Dummy" />
  </div>
`;

const IFrameSettings = () => {
  const { formatMessage: t } = useIntl();
  const { allowedOrigins, loading, error } = useAllowedOrigins();

  return (
    <CCol>
      <CCard>
        <CCardHeader className={s.headerWrapper}>
          <h4>{t({ id: 'page_titles.iFrameSettings' })}</h4>
          <AllowedOriginsButton {...{ allowedOrigins, loading }} />
        </CCardHeader>
        <CCardBody>
          {error ? <AlertGraphQLError error={error} /> : <AllowedOriginsTable {...{ allowedOrigins, loading }} />}
        </CCardBody>
        <CCardBody>
          <CopyBlock language="html" text={codeSnippet} theme={github} wrapLines codeBlock />
        </CCardBody>
      </CCard>
    </CCol>
  );
};

export default IFrameSettings;
