import gql from 'graphql-tag';
import { useMutation } from '@apollo/react-hooks';
import ALLOWED_ORIGINS_FRAGMENT from 'graphql/fragments/allowedOrigins';
import { useCallback } from 'react';
import { ALLOWED_ORIGINS } from './useAllowedOrigins';

export const ADD_ALLOWED_ORIGIN = gql`
  mutation addAllowedOrigin($allowedOrigin: String!) {
    addAllowedOrigin(allowedOrigin: $allowedOrigin) {
      ...allowedOrigins
    }
  }
  ${ALLOWED_ORIGINS_FRAGMENT}
`;

export const DELETE_ALLOWED_ORIGIN = gql`
  mutation deleteAllowedOrigin($allowedOrigin: String!) {
    deleteAllowedOrigin(allowedOrigin: $allowedOrigin) {
      ...allowedOrigins
    }
  }
  ${ALLOWED_ORIGINS_FRAGMENT}
`;

const useAllowedOriginMutation = (isAdding = false, isRefetch = false) => {
  const [allowedOriginMutation] = useMutation(isAdding ? ADD_ALLOWED_ORIGIN : DELETE_ALLOWED_ORIGIN);

  return useCallback(
    async ({ allowedOrigin }) => {
      await allowedOriginMutation({
        variables: { allowedOrigin },
        ...(isRefetch
          ? {
              refetchQueries: [{ query: ALLOWED_ORIGINS }],
              awaitRefetchQueries: true,
            }
          : {}),
      });
    },
    [allowedOriginMutation, isRefetch],
  );
};

export default useAllowedOriginMutation;
