import { useCallback } from 'react';
import gql from 'graphql-tag';
import { useMutation } from '@apollo/react-hooks';
import { GET_ADMINS } from './useGetAllAdmins';

const CHANGE_USER_ACCOUNT_ACTIVATION_STATUS_MUTATION = gql`
  mutation changeUserAccountActivationStatus($_id: ID!, $activationStatus: UserAccountActivationStatus!) {
    changeUserAccountActivationStatus(_id: $_id, activationStatus: $activationStatus)
  }
`;

const useChangeUserAccountActivationStatus = () => {
  const [changeUserAccountActivationStatus] = useMutation(CHANGE_USER_ACCOUNT_ACTIVATION_STATUS_MUTATION);

  const handleChangeUserAccountActivationStatus = useCallback(
    async ({ deactivated, _id }) => {
      await changeUserAccountActivationStatus({
        variables: { _id, activationStatus: deactivated ? 'activated' : 'deactivated' },
        refetchQueries: [{ query: GET_ADMINS }],
        awaitRefetchQueries: true,
      });
    },
    [changeUserAccountActivationStatus],
  );

  return handleChangeUserAccountActivationStatus;
};

export default useChangeUserAccountActivationStatus;
