import { useMemo } from 'react';
import gql from 'graphql-tag';
import { useQuery } from '@apollo/react-hooks';
import get from 'lodash/get';
import { COUNT_TABLE_RECORDS } from 'constants/constants';

export const CO2_SAVINGS_REPORT = gql`
  query co2SavingsByProjects($limit: Int!, $offset: Int!, $filter: String) {
    co2SavingsByProjects(limit: $limit, offset: $offset, filter: $filter) {
      count
      co2Calculations {
        _id
        co2_emissions_before {
          result {
            energy_ghg_balance_per_year {
              calculated_value
              user_input
            }
          }
        }
        co2_emissions_after {
          result {
            energy_ghg_balance_per_year {
              calculated_value
              user_input
            }
          }
        }
        energy_renovations {
          new_windows {
            co2_saving
          }
          insulation_top_ceiling {
            co2_saving
          }
          insulation_basement_ceiling {
            co2_saving
          }
          insulation_facade {
            co2_saving
          }
          controlled_living_space_ventilation {
            co2_saving
          }
          solar_power_system {
            co2_saving
          }
          heating_system {
            co2_saving
          }
        }
        project {
          _id
          name
        }
      }
    }
  }
`;

const useCO2SavingsByProjects = ({ currentPage = 1, filter = '' }) => {
  const offset = COUNT_TABLE_RECORDS * (currentPage - 1);

  const { data, loading, error } = useQuery(CO2_SAVINGS_REPORT, {
    variables: { filter, offset, limit: COUNT_TABLE_RECORDS },
  });

  const co2Calculations = useMemo(() => get(data, 'co2SavingsByProjects.co2Calculations', []), [data]);
  const total = useMemo(() => get(data, 'co2SavingsByProjects.count', 0), [data]);

  return { co2Calculations, total, loading, error };
};

export default useCO2SavingsByProjects;
