import { Switch, Route, Redirect } from 'react-router-dom';
import React from 'react';
import get from 'lodash/get';
import NotFound from 'pages/NotFound';
import Projects from 'pages/Projects';
import AdminsPage from 'pages/AdminsPage';
import AppLayout from 'components/layouts/AppLayout';
import Logout from 'pages/Logout';
import InitTwoFA from 'pages/InitTwoFA';
import useUser from 'hooks/useUser';
import CO2SavingsReport from 'pages/CO2SavingsReport';
import CO2SavingsByProjects from 'pages/CO2SavingsByProjects';
import HypoNoeApiRequestsReport from 'pages/HypoNoeApiRequestsReport';
import ProjectPDFRequestsPage from 'pages/ProjectPDFRequestsPage';
import IFrameSettings from 'pages/IFrameSettings';
import PriceHubbleApiLogsPage from 'pages/PriceHubbleApiLogs';
import EffiApiLogsPage from 'pages/EffiApiLogs';
import routePaths, { routePatterns } from './route-paths';

const routes = [
  {
    path: routePaths.home,
    exact: true,
    component: () => <Redirect to={routePatterns.projects.stringify({ page: 1 })} />,
  },
  {
    path: routePaths.logout,
    component: Logout,
  },
  {
    path: routePaths.admins,
    component: AdminsPage,
  },
  {
    path: routePaths.projectPDFRequests,
    component: ProjectPDFRequestsPage,
  },
  {
    path: routePaths.projects,
    component: Projects,
  },
  {
    path: routePaths.co2SavingsReport,
    component: CO2SavingsReport,
  },
  {
    path: routePaths.co2SavingsByProjects,
    component: CO2SavingsByProjects,
  },
  {
    path: routePaths.hypoNoeApiRequestsReport,
    component: HypoNoeApiRequestsReport,
  },
  {
    path: routePaths.iFrameSettings,
    component: IFrameSettings,
  },
  {
    path: routePaths.priceHubbleApiLogs,
    component: PriceHubbleApiLogsPage,
  },
  // {
  //   path: routePaths.message,
  //   component: Feedback,
  // },
  // {
  //   path: routePaths.messages,
  //   component: Messages,
  // },
  // {
  //   path: routePaths.financingRequests,
  //   component: FinancingRequests,
  // },
  // {
  //   path: routePaths.notifications,
  //   component: Notifications,
  // },
  // {
  //   path: routePaths.loanConsultants,
  //   component: LoanConsultantsPage,
  // },
  // {
  //   path: routePaths.customerDataAnalytics,
  //   component: CustomerDataAnalytics,
  // },
];

const AppRouter = () => {
  const [me] = useUser();
  const is2FAEnabled = get(me, 'is2FAEnabled', null);
  return (
    <>
      <Switch>
        {!is2FAEnabled && (
          <Route path={routePaths.initTwoFA} exact>
            <InitTwoFA />
          </Route>
        )}
        {!is2FAEnabled ? <Redirect to={routePaths.initTwoFA} /> : null}
        {me?.deactivated ? <Redirect to={routePaths.logout} /> : null}
        <AppLayout>
          <Switch>
            {routes.map(({ path, component, ...rest }) => (
              <Route key={path} path={path} component={component} exact {...rest} />
            ))}
            <Route path="*" component={NotFound} />
          </Switch>
        </AppLayout>
      </Switch>
    </>
  );
};

export default AppRouter;
