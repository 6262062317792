import React from 'react';
import { CBadge, CButton, CDataTable } from '@coreui/react';
import Pagination from 'components/common/Pagination';
import { COUNT_TABLE_RECORDS } from 'constants/constants';
import Modal, { useModal } from 'components/common/Modal';
import CIcon from '@coreui/icons-react';

import { cilInfo } from '@coreui/icons';
import { isObject, isUndefined } from 'lodash';
import { formatDate } from 'utils/helpers';

const eventType2BadgeColor = {
  error: 'primary',
  request: 'info',
  response: 'success',
};

const dateKeys = ['createdAt', 'updatedAt'];

const ParamsTable = ({ params }) => {
  return (
    <table className="table">
      <tbody>
        {Object.entries(JSON.parse(params)).map(([key, value]) => {
          return (
            <tr key={key}>
              <td>{key}</td>
              <td>
                {Array.isArray(value) ? (
                  <table>
                    <tbody>
                      {value.map((obj, idx) => {
                        const foo = isObject(obj)
                          ? Object.entries(obj).reduce(
                              (acc, [k, v]) => ({
                                ...acc,
                                [k]: dateKeys.includes(k) ? formatDate(new Date(v)) : v,
                              }),
                              {},
                            )
                          : obj;
                        return (
                          <tr key={`unique-key-${idx}`}>
                            <td>
                              <pre>{JSON.stringify(foo)}</pre>
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                ) : null}
                {!Array.isArray(value) && !isUndefined(value) ? (
                  <>
                    {dateKeys.includes(key) ? (
                      formatDate(new Date(value), 'dd MMMM yyyy HH:mm:ss')
                    ) : (
                      <pre>{typeof value === 'string' ? value : JSON.stringify(value, null, 2)}</pre>
                    )}
                  </>
                ) : null}
              </td>
            </tr>
          );
        })}
      </tbody>
    </table>
  );
};

const ApiLogsTable = ({ items, changePage, currentPage, columns, total, loading }) => {
  const { showModal, hideModal, isVisible, modalProps } = useModal();

  return (
    <>
      <CDataTable
        fields={columns}
        hover
        items={items}
        itemsPerPage={COUNT_TABLE_RECORDS}
        loading={loading}
        size="sm"
        scopedSlots={{
          eventType: ({ eventType }) => (
            <td>
              <CBadge color={eventType2BadgeColor[eventType]}>{eventType}</CBadge>
            </td>
          ),
          extraDetails: ({ extraDetails }) => (
            <td>
              {extraDetails ? (
                <CButton color="secondary" onClick={() => showModal(extraDetails)}>
                  <CIcon content={cilInfo} />
                </CButton>
              ) : null}
            </td>
          ),
          data: ({ data }) => (
            <td>
              {data ? (
                <CButton color="secondary" onClick={() => showModal(data)}>
                  <CIcon content={cilInfo} />
                </CButton>
              ) : null}
            </td>
          ),
        }}
      />

      <Modal isVisible={isVisible} close={hideModal}>
        {typeof modalProps === 'string' ? <ParamsTable params={modalProps} /> : ''}
      </Modal>
      <Pagination {...{ changePage, currentPage, total }} />
    </>
  );
};

export default ApiLogsTable;
