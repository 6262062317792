import React from 'react';
import { Formik, Field, Form } from 'formik';
import { useIntl } from 'react-intl';
import * as yup from 'yup';
import { CCard, CCardBody, CCardGroup, CCol, CContainer, CRow } from '@coreui/react';
import ErrorMessage from 'components/common/ErrorMessage';
import { __ } from 'utils/helpers';
import Button from 'components/common/Button';
import AuthInput from './AuthInput';

const loginShape = {
  email: yup
    .string()
    .required(__('errors.email_required'))
    .email(__('errors.email_invalid')),
  plainPassword: yup.string().required(__('errors.password_required')),
};

const loginParamsSchema = yup.object().shape(loginShape);

const initialValues = {
  email: '',
  plainPassword: '',
};

export default function LoginForm({ onSubmit }) {
  const { formatMessage: t } = useIntl();

  return (
    <Formik initialValues={initialValues} validationSchema={loginParamsSchema} onSubmit={onSubmit}>
      {({ isSubmitting, errors }) => (
        <Form>
          <div className="c-app c-default-layout flex-row align-items-center">
            <CContainer>
              <CRow className="justify-content-center">
                <CCol md="12" xl="8">
                  <CCardGroup>
                    <CCard className="p-4">
                      <CCardBody>
                        <h1>{t({ id: 'page_titles.login' })}</h1>
                        <p className="text-muted">{t({ id: 'auth.sign_in_message' })}</p>
                        <Field
                          component={AuthInput}
                          type="email"
                          icon="cil-user"
                          name="email"
                          label={t({ id: 'auth.email' })}
                        />
                        <Field
                          component={AuthInput}
                          type="password"
                          icon="cil-lock-locked"
                          name="plainPassword"
                          label={t({ id: 'auth.password' })}
                        />
                        <CRow>
                          <CCol xs="12" className="d-flex justify-content-end">
                            <Button type="submit" disabled={isSubmitting} loading={isSubmitting}>
                              {t({ id: 'auth.sign_in_submit' })}
                            </Button>
                          </CCol>
                        </CRow>
                        <ErrorMessage message={errors.form} />
                      </CCardBody>
                    </CCard>
                    <CCard className="bg-secondary">
                      <CCardBody className="d-flex align-items-center justify-content-center">
                        <img src={require('assets/images/logo.png')} alt="Logo" />
                      </CCardBody>
                    </CCard>
                  </CCardGroup>
                </CCol>
              </CRow>
            </CContainer>
          </div>
        </Form>
      )}
    </Formik>
  );
}
