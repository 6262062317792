import { useMemo } from 'react';
import gql from 'graphql-tag';
import { useQuery } from '@apollo/react-hooks';
import get from 'lodash/get';
import { COUNT_TABLE_RECORDS } from 'constants/constants';
import settings from 'config/settings';

const { rootUrl } = settings;

const GET_PROJECTS = gql`
  query allProjects($limit: Int!, $offset: Int!, $filter: String, $rootUrl: String) {
    allProjects(limit: $limit, offset: $offset, filter: $filter) {
      count
      filteredProjects {
        _id
        name
        utm_params
        pdf(url: $rootUrl)
        price
        budget
        user {
          emails {
            address
          }
        }
        archived
        created_at
        last_updated_at
        clientIP
      }
    }
  }
`;

const useGetProjects = ({ currentPage, filter }) => {
  const offset = COUNT_TABLE_RECORDS * (currentPage - 1);

  const { data, loading, error } = useQuery(GET_PROJECTS, {
    variables: { rootUrl, filter, offset, limit: COUNT_TABLE_RECORDS },
  });

  const projects = useMemo(() => get(data, 'allProjects.filteredProjects', []), [data]);
  const total = get(data, 'allProjects.count', 0);

  return { projects, total, loading, error };
};

export default useGetProjects;
