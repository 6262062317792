import gql from 'graphql-tag';
import { useQuery } from '@apollo/react-hooks';
import ALLOWED_ORIGINS_FRAGMENT from 'graphql/fragments/allowedOrigins';

export const ALLOWED_ORIGINS = gql`
  query settings {
    settings {
      ...allowedOrigins
    }
  }
  ${ALLOWED_ORIGINS_FRAGMENT}
`;

const useAllowedOrigins = () => {
  const { data, loading, error } = useQuery(ALLOWED_ORIGINS, {
    fetchPolicy: 'cache-and-network',
  });
  return { allowedOrigins: data?.settings?.allowedOrigins, loading, error };
};

export default useAllowedOrigins;
