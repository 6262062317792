import { CCardBody, CCardHeader, CDataTable } from '@coreui/react';
import Loading from 'components/common/Loading';
import Modal from 'components/common/Modal';
import { COUNT_TABLE_RECORDS } from 'constants/constants';
import useGetHypoNoeApiRequestDetails from 'hooks/hypoNoeApiRequests/useGetHypoNoeApiRequest';
import { cloneDeep, flattenDeep, get } from 'lodash';
import React, { useMemo } from 'react';
import { useIntl } from 'react-intl';
import { formatDate } from 'utils/helpers';
import s from './APIRequestDetailsModal.module.scss';

function extractRows(acc, queryParameter) {
  if (!queryParameter.parameter) return acc;
  const { parameter, value, ...rest } = queryParameter;
  let v = value;
  if (parameter === 'DateOfBirth') v = formatDate(v, 'do MMMM yyyy');
  return [...acc, { parameter, value: `${v}`, ...rest }];
}

const emptyRow = { parameter: 'Partner', value: '', highlighted: true };

const CustomerDetailsTable = ({ customers, t }) => {
  const fields = useMemo(
    () => [
      { key: 'parameter', label: t({ id: 'hypo_noe_api_request.parameter' }) },
      { key: 'value', label: t({ id: 'hypo_noe_api_request.value' }) },
    ],
    [t],
  );

  const items = useMemo(
    () =>
      flattenDeep(
        customers.map((_customer, idx) => {
          const customer = cloneDeep(_customer);
          if (idx !== customers.length - 1) customer.emptyRow = emptyRow;
          return Object.values(customer).reduce(extractRows, []);
        }),
      ),
    [customers],
  );

  return (
    <>
      <h3>{t({ id: 'hypo_noe_api_request.customers' })}</h3>
      <CDataTable
        items={items}
        fields={fields}
        itemsPerPage={COUNT_TABLE_RECORDS}
        scopedSlots={{
          parameter: ({ parameter, highlighted }) => {
            return <td className={highlighted ? s.highlightedRow : s.row}>{parameter}</td>;
          },
          value: ({ value, highlighted }) => {
            return <td className={highlighted ? s.highlightedRow : s.row}>{value}</td>;
          },
        }}
      />
    </>
  );
};

const RequestDetailsTable = ({ requestDetails }) => {
  const { formatMessage: t } = useIntl();
  const customers = get(requestDetails, 'request_params.customers', []);

  const fields = useMemo(
    () => [
      { key: 'parameter', label: t({ id: 'hypo_noe_api_request.parameter' }) },
      { key: 'value', label: t({ id: 'hypo_noe_api_request.value' }) },
    ],
    [t],
  );

  const items = useMemo(() => Object.values(requestDetails.request_params).reduce(extractRows, []), [requestDetails]);

  return (
    <>
      <CDataTable
        items={items}
        fields={fields}
        itemsPerPage={COUNT_TABLE_RECORDS}
        scopedSlots={{
          parameter: ({ parameter }) => <td className={s.row}>{parameter}</td>,
          value: ({ value }) => <td className={s.row}>{value}</td>,
        }}
      />
      <CustomerDetailsTable {...{ customers, t }} />
    </>
  );
};

const RequestDetails = ({ requestId }) => {
  const [requestDetails, loading] = useGetHypoNoeApiRequestDetails(requestId);

  if (loading) return <Loading size={30} />;

  return <RequestDetailsTable {...{ requestDetails }} />;
};

const APIRequestDetailsModal = ({ requestId, isVisible, hideModal }) => {
  const { formatMessage: t } = useIntl();
  if (!requestId) return null;

  return (
    <Modal isVisible={isVisible} close={hideModal}>
      <CCardHeader>
        <h4>{t({ id: 'page_titles.hypo_noe_api_requests_report' })}</h4>
      </CCardHeader>
      <CCardBody>
        <RequestDetails {...{ requestId }} />
      </CCardBody>
    </Modal>
  );
};

export default APIRequestDetailsModal;
