import React, { useCallback, useMemo } from 'react';
import { useIntl } from 'react-intl';
import { CButton, CDataTable } from '@coreui/react';
import { confirmAlert } from 'react-confirm-alert';
import useAllowedOriginMutation from 'hooks/settings/useAllowedOriginMutation';
import { toast } from 'react-toastify';
import { toastErrorMessage } from 'utils/helpers';

const useAllowedOriginHandler = () => {
  const allowedOriginMutation = useAllowedOriginMutation();
  const { formatMessage: t } = useIntl();

  const handleDelete = useCallback(
    async (allowedOrigin) => {
      confirmAlert({
        title: t({ id: 'iFrameSettings.delete_allowed_origin.title' }, { value: allowedOrigin }),
        message: t({ id: 'iFrameSettings.delete_allowed_origin.message' }, { value: allowedOrigin }),
        buttons: [
          {
            label: t({ id: 'buttons.cancel' }),
          },
          {
            label: t({ id: 'buttons.delete' }),
            onClick: async () => {
              try {
                await allowedOriginMutation({ allowedOrigin });
                toast.success(t({ id: 'iFrameSettings.delete_allowed_origin.success' }));
              } catch (error) {
                toastErrorMessage(error);
              }
            },
          },
        ],
      });
    },
    [t, allowedOriginMutation],
  );
  return handleDelete;
};

const fields = (t) => [
  { key: 'allowedOrigin', label: t({ id: 'iFrameSettings.allowed_origin' }) },
  { key: 'delete', label: '' },
];

const AllowedOriginsTable = ({ allowedOrigins, loading }) => {
  const { formatMessage: t } = useIntl();
  const allowedOriginsFields = useMemo(() => fields(t), [t]);
  const allowedOriginHandler = useAllowedOriginHandler();

  return (
    <CDataTable
      fields={allowedOriginsFields}
      hover
      items={allowedOrigins}
      loading={loading}
      size="sm"
      scopedSlots={{
        allowedOrigin: (allowedOrigin) => <td className="width-100">{allowedOrigin}</td>,
        delete: (allowedOrigin) => (
          <td>
            <CButton color="primary" variant="outline" onClick={() => allowedOriginHandler(allowedOrigin)}>
              {t({ id: 'buttons.delete' })}
            </CButton>
          </td>
        ),

        allowedOriginHandler,
      }}
    />
  );
};

export default AllowedOriginsTable;
