import React, { useMemo } from 'react';
import { formatDate } from 'utils/helpers';
import get from 'lodash/get';
import { CDataTable, CBadge, CButton } from '@coreui/react';
import CIcon from '@coreui/icons-react';
import { FormattedMessage, useIntl } from 'react-intl';
import { COUNT_TABLE_RECORDS } from 'constants/constants';
import { cibAdobeAcrobatReader, cilInfo } from '@coreui/icons';
import Pagination from 'components/common/Pagination';
import settings from 'config/settings';
import s from './ProjectsTable.module.scss';

const ProjectsTable = ({ projects, changePage, currentPage, total, loading }) => {
  const { formatMessage: t } = useIntl();

  const tableHead = useMemo(
    () => [
      { key: 'name', label: t({ id: 'projects.name' }) },
      { key: 'price', label: t({ id: 'projects.price' }) },
      { key: 'created', label: t({ id: 'projects.created' }) },
      { key: 'lastUpdated', label: t({ id: 'projects.last_updated' }) },
      { key: 'clientIP', label: t({ id: 'projects.clientIP' }) },
      { key: 'pdf', label: t({ id: 'projects.pdf' }) },
      { key: 'excel', label: t({ id: 'projects.excel' }) },
      { key: 'utmParams', label: t({ id: 'projects.utmParams' }) },
    ],
    [t],
  );

  const items = useMemo(
    () =>
      projects.map(({ _id, user, created_at, utm_params, last_updated_at, type, ...rest }) => ({
        id: _id,
        email: get(user, 'emails[0].address'),
        created: formatDate(created_at),
        lastUpdated: formatDate(last_updated_at),
        utmParams: utm_params || '',
        ...rest,
      })),
    [projects],
  );

  return (
    <>
      <CDataTable
        addTableClasses={s.projectsTable}
        fields={tableHead}
        hover
        items={items}
        itemsPerPage={COUNT_TABLE_RECORDS}
        loading={loading}
        size="sm"
        scopedSlots={{
          name: ({ name, archived }) => (
            <td>
              <span>
                {name}{' '}
                {archived && (
                  <CBadge color="warning">
                    <FormattedMessage id="projects.archived" />
                  </CBadge>
                )}
              </span>
            </td>
          ),
          creator: ({ creator, email }) => (
            <td>
              <span>{creator}</span> {email ? <a href={`mailto:${email}`}>{email}</a> : ''}
            </td>
          ),
          pdf: ({ pdf }) => (
            <td>
              <a href={pdf} target="_blank" rel="noopener noreferrer">
                <CButton color="primary" variant="outline">
                  <CIcon content={cibAdobeAcrobatReader} />
                </CButton>
              </a>
            </td>
          ),
          excel: ({ id }) => (
            <td>
              <a href={`${settings.xlsxProjectFullDetails}?projectId=${id}`} target="_blank" rel="noopener noreferrer">
                <CButton color="primary" variant="outline">
                  <CIcon content={cilInfo} />
                </CButton>
              </a>
            </td>
          ),
        }}
      />
      <Pagination {...{ changePage, currentPage, total }} />
    </>
  );
};

export default ProjectsTable;
