import { useCallback } from 'react';
import gql from 'graphql-tag';
import { useMutation } from '@apollo/react-hooks';
import { GET_ADMINS } from './useGetAllAdmins';

const CREATE_USER_MUTATION = gql`
  mutation createUser($email: String!, $plainPassword: String, $roles: [Role], $profile: CreateUserProfileInput!) {
    createUser(email: $email, plainPassword: $plainPassword, roles: $roles, profile: $profile) {
      _id
    }
  }
`;

const useCreateAdmin = () => {
  const [createUserMutation] = useMutation(CREATE_USER_MUTATION);

  return useCallback(
    async ({ email, password, firstName, lastName }) => {
      await createUserMutation({
        variables: {
          email,
          plainPassword: password,
          roles: ['admin'],
          profile: {
            firstName,
            lastName,
          },
        },
        refetchQueries: [{ query: GET_ADMINS }],
        awaitRefetchQueries: true,
      });
    },
    [createUserMutation],
  );
};

export default useCreateAdmin;
