import React, { useMemo } from 'react';
import { useIntl } from 'react-intl';
import { CButton, CDataTable } from '@coreui/react';
import CIcon from '@coreui/icons-react';
import { cibAdobeAcrobatReader } from '@coreui/icons';
import Pagination from 'components/common/Pagination';
import { COUNT_TABLE_RECORDS } from 'constants/constants';

const fields = (t) => [
  { key: 'name', label: t({ id: 'project_pdf_requests.name' }) },
  { key: 'email', label: t({ id: 'project_pdf_requests.email' }) },
  { key: 'phoneNumber', label: t({ id: 'project_pdf_requests.phone_number' }) },
  {
    key: 'projectImplementationStartPoint',
    label: t({ id: 'project_pdf_requests.project_implementation_start_point.label' }),
  },
  { key: 'projectName', label: t({ id: 'project_pdf_requests.projectName' }) },
  { key: 'utmParams', label: t({ id: 'project_pdf_requests.utmParams' }) },
  { key: 'created_at', label: t({ id: 'project_pdf_requests.created_at' }) },
  { key: 'clientIP', label: t({ id: 'project_pdf_requests.clientIP' }) },
  { key: 'pdf', label: t({ id: 'project_pdf_requests.pdf' }) },
];

const ProjectPDFRequestsTable = ({ projectPDFRequests, changePage, currentPage, total, loading }) => {
  const { formatMessage: t } = useIntl();
  const projectPDFRequestFields = useMemo(() => fields(t), [t]);
  return (
    <>
      <CDataTable
        fields={projectPDFRequestFields}
        hover
        items={projectPDFRequests}
        itemsPerPage={COUNT_TABLE_RECORDS}
        loading={loading}
        size="sm"
        scopedSlots={{
          email: ({ email }) => (
            <td>
              <a href={`mailto:${email}`}>{email}</a>
            </td>
          ),
          projectImplementationStartPoint: ({ projectImplementationStartPoint }) => (
            <td>
              {projectImplementationStartPoint
                ? t({
                    id: `project_pdf_requests.project_implementation_start_point.options.${projectImplementationStartPoint}`,
                  })
                : ''}
            </td>
          ),
          pdf: ({ pdf }) => (
            <td>
              <a href={pdf} target="_blank" rel="noopener noreferrer">
                <CButton color="primary" variant="outline">
                  <CIcon content={cibAdobeAcrobatReader} />
                </CButton>
              </a>
            </td>
          ),
        }}
      />
      <Pagination {...{ changePage, currentPage, total }} />
    </>
  );
};

export default ProjectPDFRequestsTable;
