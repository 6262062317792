import { CCardBody, CCardFooter } from '@coreui/react';
import Button from 'components/common/Button';
import Modal, { useModal } from 'components/common/Modal';
import TextInput from 'components/inputs/TextInput';
import { FastField, Form, Formik } from 'formik';
import React, { useCallback, useMemo } from 'react';
import { useIntl } from 'react-intl';
import * as yup from 'yup';
import { toastErrorMessage } from 'utils/helpers';
import { toast } from 'react-toastify';
import useAllowedOriginMutation from 'hooks/settings/useAllowedOriginMutation';

const validationSchema = ({ allowedOrigins, t }) =>
  yup.object().shape({
    allowedOrigin: yup
      .string()
      .required()
      .test(
        'check-unique',
        t({ id: 'iFrameSettings.unique' }),
        (value) => !allowedOrigins || (value && Array.isArray(allowedOrigins) && !allowedOrigins.includes(value)),
      )
      .test('check-url', t({ id: 'iFrameSettings.url' }), (value) => {
        try {
          // eslint-disable-next-line no-new
          new URL(value);
          return true;
        } catch {
          return false;
        }
      })
      .label(t({ id: 'iFrameSettings.allowed_origin' })),
  });

const AdminForm = () => {
  const { formatMessage: t } = useIntl();
  return (
    <FastField
      isRequired
      name="allowedOrigin"
      component={TextInput}
      label={t({ id: 'iFrameSettings.allowed_origin' })}
    />
  );
};

const AllowedOriginFormik = ({ allowedOrigins, hideModal }) => {
  const allowedOrigin = useAllowedOriginMutation(true, !allowedOrigins);

  const { formatMessage: t } = useIntl();

  const initialValues = useMemo(() => ({ allowedOrigin: '' }), []);

  const onSubmit = useCallback(
    async (values, helpers) => {
      try {
        await allowedOrigin(values);
        toast.success(t({ id: 'iFrameSettings.add_allowed_origin_success' }));
        hideModal();
      } catch (error) {
        toastErrorMessage({
          error,
          t,
          errorResolver: (e) => (e.message === 'Duplicated' ? t({ id: 'iFrameSettings.duplicated' }) : undefined),
        });
      } finally {
        helpers.setSubmitting(false);
      }
    },
    [allowedOrigin, hideModal, t],
  );

  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      onSubmit={onSubmit}
      validationSchema={validationSchema({ allowedOrigins, t })}
    >
      {({ isSubmitting }) => (
        <Form>
          <CCardBody>
            <AdminForm />
          </CCardBody>
          <CCardFooter className="text-right">
            <Button
              color="danger"
              onClick={() => {
                hideModal();
              }}
            >
              {t({ id: 'buttons.cancel' })}
            </Button>{' '}
            <Button type="submit" loading={isSubmitting} disabled={isSubmitting}>
              {t({ id: 'buttons.submit' })}
            </Button>
          </CCardFooter>
        </Form>
      )}
    </Formik>
  );
};

const AllowedOriginsButton = ({ allowedOrigins, loading }) => {
  const { showModal, hideModal, isVisible } = useModal();
  const { formatMessage: t } = useIntl();

  return (
    <>
      <Button color="primary" onClick={showModal} disabled={loading} loading={loading}>
        {t({ id: 'iFrameSettings.add_allowed_origin_btn' })}
      </Button>
      <Modal isVisible={isVisible} close={hideModal}>
        <AllowedOriginFormik allowedOrigins={allowedOrigins} hideModal={hideModal} />
      </Modal>
    </>
  );
};

export default AllowedOriginsButton;
