import { useCallback, useMemo } from 'react';
import useGetSearchParams from './useGetSearchParams';

const usePagination = ({ searchParamKey = 'page' } = {}) => {
  const { searchParams, setSearchParams } = useGetSearchParams();

  const currentPage = useMemo(() => Number(searchParams[searchParamKey]) || 1, [searchParamKey, searchParams]);

  const onChangeCurrentPage = useCallback(
    (page) => setSearchParams({ ...searchParams, [searchParamKey]: String(page) }),
    [searchParamKey, searchParams, setSearchParams],
  );

  const handleChangePage = useCallback(
    (_page) => {
      onChangeCurrentPage(_page);
      window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    },
    [onChangeCurrentPage],
  );

  return { changePage: handleChangePage, currentPage };
};

export default usePagination;
