import React, { useMemo } from 'react';
import 'components/common/AlertOverlay.scss';
import AlertGraphQLError from 'components/common/AlertGraphQLError';
import { CCard, CCardBody, CCardHeader, CCol, CFormGroup, CInput, CLabel } from '@coreui/react';
import ProjectPDFRequestsTable from 'components/projectPDFRequests/ProjectPDFRequestsTable';
import { useIntl } from 'react-intl';
import useGetProjectPDFRequests from 'hooks/projectPDFRequests/useGetProjectPDFRequests';
import { formatDate } from 'utils/helpers';
import usePagination from 'hooks/usePagination';
import useFilterValue from 'hooks/useFilterValue';

const ProjectPDFRequestsPage = () => {
  const { formatMessage: t } = useIntl();
  const { currentPage, changePage } = usePagination();
  const { handleChange, debouncedFilterValue, filter } = useFilterValue({ changePage });
  const { projectPDFRequests: data, total, loading, error } = useGetProjectPDFRequests({
    currentPage,
    filter: debouncedFilterValue,
  });

  const projectPDFRequests = useMemo(
    () =>
      data.map(({ project, meta, user, project_implementation_start_point, created_at }) => ({
        email: user.email,
        phoneNumber: user.phone_number || '',
        projectImplementationStartPoint: project_implementation_start_point,
        name: `${user.firstName} ${user.lastName}`,
        utmParams: project.utm_params || '',
        projectName: project.name,
        created_at: formatDate(created_at),
        clientIP: meta.clientIP,
        pdf: project.pdf,
      })),
    [data],
  );

  return (
    <CCol>
      <CCard>
        <CCardHeader>
          <h4>{t({ id: 'project_pdf_requests.title' })}</h4>
        </CCardHeader>

        <CCardBody>
          {error ? (
            <AlertGraphQLError error={error} />
          ) : (
            <>
              <CFormGroup>
                <CLabel htmlFor="filter">{t({ id: 'project_pdf_requests.filter' })}</CLabel>
                <CInput id="filter" type="search" name="filter" value={filter} onChange={handleChange} />
              </CFormGroup>
              <ProjectPDFRequestsTable {...{ projectPDFRequests, changePage, currentPage, total, loading }} />;
            </>
          )}
        </CCardBody>
      </CCard>
    </CCol>
  );
};

export default ProjectPDFRequestsPage;
