import React from 'react';
import get from 'lodash/get';
import { CFormGroup, CLabel, CInput, CInvalidFeedback } from '@coreui/react';

const TextInput = ({ field, form, disabled = false, type = 'text', label }) => {
  const { name, value } = field;
  const { touched, errors, setFieldValue, isSubmitting } = form;
  const isDisabled = isSubmitting || disabled;

  const getClassNameForInput = get(touched, name) && get(errors, name) ? 'form-control is-invalid' : 'form-control';

  const handleChange = React.useCallback(
    (e) => {
      setFieldValue(name, e.target.value);
    },
    [name, setFieldValue],
  );

  return (
    <div>
      <CFormGroup>
        <CLabel htmlFor={name}>{label}</CLabel>
        <CInput
          {...field}
          id={name}
          disabled={isDisabled}
          type={type}
          value={value}
          className={getClassNameForInput}
          onChange={handleChange}
        />
        {get(touched, name) && get(errors, name) && (
          <CInvalidFeedback className="help-block">{errors[name]}</CInvalidFeedback>
        )}
      </CFormGroup>
    </div>
  );
};

export default TextInput;
