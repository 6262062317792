import React from 'react';
import { CFooter } from '@coreui/react';
import { changeLanguage } from 'utils/helpers';

const Footer = () => {
  return (
    <CFooter fixed={false} onClick={changeLanguage}>
      <div className="mfs-auto">
        <span className="mr-1">
          Copyright © {new Date().getFullYear()} Talo IT GmbH
        </span>
      </div>
    </CFooter>
  );
};

export default Footer;
