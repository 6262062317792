import React from 'react';
import { CCard, CCardBody, CCardHeader, CCol, CFormGroup, CInput, CLabel } from '@coreui/react';
import AlertGraphQLError from 'components/common/AlertGraphQLError';
import Button from 'components/common/Button';
import CO2SavingsByProjectsTable from 'components/co2SavingsByProjects/CO2SavingsByProjectsTable';
import useCO2SavingsByProjects from 'hooks/useCO2SavingsByProjects';
import useFilterValue from 'hooks/useFilterValue';
import usePagination from 'hooks/usePagination';
import { useIntl } from 'react-intl';
import { Link } from 'react-router-dom';
import routePaths from 'router/route-paths';

const CO2SavingsByProjects = () => {
  const { formatMessage: t } = useIntl();
  const { currentPage, changePage } = usePagination();
  const { filter, handleChange, debouncedFilterValue } = useFilterValue({ changePage });
  const { co2Calculations, total, loading, error } = useCO2SavingsByProjects({
    currentPage,
    filter: debouncedFilterValue,
  });

  return (
    <CCol>
      <CCard>
        <CCardHeader style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <h4>{t({ id: 'page_titles.projects' })}</h4>
          <Link to={routePaths.co2SavingsReport}>
            <Button>{t({ id: 'co2_report.back_to_co2_report' })}</Button>
          </Link>
        </CCardHeader>
        <CCardBody>
          <CFormGroup>
            <CLabel htmlFor="filter">{t({ id: 'projects.filter' })}</CLabel>
            <CInput id="filter" type="search" name="filter" value={filter} onChange={handleChange} />
          </CFormGroup>
          {error ? (
            <AlertGraphQLError error={error} />
          ) : (
            <CO2SavingsByProjectsTable {...{ total, co2Calculations, currentPage, changePage, loading }} />
          )}
        </CCardBody>
      </CCard>
    </CCol>
  );
};

export default CO2SavingsByProjects;
