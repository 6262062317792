import gql from 'graphql-tag';
import get from 'lodash/get';
import { useQuery } from '@apollo/react-hooks';
import { COUNT_TABLE_RECORDS } from 'constants/constants';

export const API_LOGS = gql`
  query apiLogs($limit: Int!, $offset: Int!, $apiName: ApiName!) {
    apiLogs(limit: $limit, offset: $offset, apiName: $apiName) {
      total
      items {
        _id
        eventType
        method
        url
        statusCode
        data
        params
        details
        createdAt
      }
    }
  }
`;

const useApiLogs = ({ currentPage, apiName }) => {
  const { data, loading, error } = useQuery(API_LOGS, {
    variables: { offset: COUNT_TABLE_RECORDS * (currentPage - 1), limit: COUNT_TABLE_RECORDS, apiName },
  });

  const apiLogs = get(data, 'apiLogs.items', []);
  const total = get(data, 'apiLogs.total', 0);

  return { apiLogs, total, loading, error };
};

export default useApiLogs;
