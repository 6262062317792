import React from 'react';
import classnames from 'classnames';
import s from './Alert.module.scss';

const colorToClassNameMap = {
  primary: s.primary,
  danger: s.danger,
};

const Alert = ({ children, color, ...props }) => {
  return (
    <div className={classnames(s.alert, colorToClassNameMap[color])} {...props}>
      {children}
    </div>
  );
};

export default Alert;
