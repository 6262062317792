import React from 'react';
import { IntlProvider } from 'react-intl';
import { BrowserRouter as Router } from 'react-router-dom';
import { ApolloProvider } from '@apollo/react-hooks';
import { ToastContainer } from 'react-toastify';
import ScrollToTop from 'components/common/ScrollToTop';
import * as icons from 'assets/icons';
import { AppContextProvider } from './contexts/AppContext';
import settings from './config/settings';
import i18nMessages from './i18n';
import RootRouter from './router/RootRouter';
import apolloClient from './graphql/apollo';
import 'normalize.css';
import 'react-confirm-alert/src/react-confirm-alert.css';
import 'react-toastify/dist/ReactToastify.css';
import 'react-quill/dist/quill.snow.css';
import '@coreui/coreui/scss/coreui.scss';
import 'rc-pagination/assets/index.css';
import './styles/index.scss';

React.icons = icons;

const locale = window?.localStorage?.getItem('language') || settings.defaultLocale;

const App = () => (
  <ApolloProvider client={apolloClient}>
    <Router basename={settings.publicURL}>
      <ScrollToTop />
      <IntlProvider locale={locale} messages={i18nMessages[locale]}>
        <AppContextProvider locale={locale}>
          <RootRouter />
        </AppContextProvider>
      </IntlProvider>
    </Router>
    <ToastContainer />
  </ApolloProvider>
);

export default App;
