import React from 'react';
import { CCard, CCardHeader, CCol, CFormGroup, CLabel, CInput, CCardBody } from '@coreui/react';
import useGetProjects from 'hooks/projects/useGetProjects';
import ProjectsTable from 'components/projects/ProjectsTable';
import { useIntl } from 'react-intl';
import usePagination from 'hooks/usePagination';
import useFilterValue from 'hooks/useFilterValue';
import Button from 'components/common/Button';
import AlertGraphQLError from 'components/common/AlertGraphQLError';
import s from './Projects.module.scss';

const xlsxProjectsReportUrl = '/api/projects-report';

export default function Projects() {
  const { formatMessage: t } = useIntl();
  const { currentPage, changePage } = usePagination();
  const { handleChange, debouncedFilterValue, filter } = useFilterValue({ changePage });
  const { projects, total, loading, error } = useGetProjects({ currentPage, filter: debouncedFilterValue });

  return (
    <CCol>
      <CCard>
        <CCardHeader className={s.header}>
          <h4>{t({ id: 'page_titles.projects' })}</h4>
          <div className={s.buttonsWrapper}>
            <a href={xlsxProjectsReportUrl} rel="noopener noreferrer" target="_blank">
              <Button color="primary">{t({ id: 'projects.xlsx_export' })}</Button>
            </a>
          </div>
        </CCardHeader>
        <CCardBody>
          {error ? (
            <AlertGraphQLError error={error} />
          ) : (
            <>
              <CFormGroup>
                <CLabel htmlFor="filter">{t({ id: 'projects.filter' })}</CLabel>
                <CInput id="filter" type="search" name="filter" value={filter} onChange={handleChange} />
              </CFormGroup>
              <ProjectsTable {...{ projects, total, currentPage, changePage, loading }} />
            </>
          )}
        </CCardBody>
      </CCard>
    </CCol>
  );
}
