import React, { useMemo } from 'react';
import {
  CCreateElement,
  CSidebar,
  CSidebarBrand,
  CSidebarNav,
  CSidebarMinimizer,
  CSidebarNavItem,
  CSidebarNavDropdown,
} from '@coreui/react';
import { useSidebar } from 'contexts/AppContext';
import { zIndexSidebar } from 'styles/variables.scss';
import { useIntl } from 'react-intl';
import { routePatterns } from 'router/route-paths';
import sidebarItems from './sidebarItems';

const Sidebar = () => {
  const { showSidebar, toggleSidebarMobile } = useSidebar();
  const { formatMessage: t } = useIntl();

  const localizedSidebarItems = useMemo(() => {
    const produceSidebarItems = (items) =>
      items
        .filter(({ hide }) => !hide)
        .map(({ name, hide, ...rest }) => {
          const newProps = { name: t({ id: name }), ...rest };
          if (Array.isArray(rest.items)) newProps.items = produceSidebarItems(rest.items);
          return newProps;
        });
    return produceSidebarItems(sidebarItems);
  }, [t]);

  return (
    <CSidebar show={showSidebar} onShowChange={toggleSidebarMobile} style={{ zIndex: zIndexSidebar }}>
      <CSidebarBrand className="d-md-down-none" to={routePatterns.projects.stringify({ page: 1 })} />
      <CSidebarNav>
        <CCreateElement
          items={localizedSidebarItems}
          components={{
            CSidebarNavItem,
            CSidebarNavDropdown: ({ items, ...props }) => {
              return (
                <CSidebarNavDropdown {...props}>
                  <CCreateElement items={items} components={{ CSidebarNavItem }} />
                </CSidebarNavDropdown>
              );
            },
          }}
        />
      </CSidebarNav>
      <CSidebarMinimizer className="c-d-md-down-none" />
    </CSidebar>
  );
};

export default React.memo(Sidebar);
