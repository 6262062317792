import React, { useMemo } from 'react';
import { useIntl } from 'react-intl';
import { CDataTable } from '@coreui/react';
import useChangeUserAccountActivationStatus from 'hooks/admins/useChangeUserAccountActivationStatus';
import { SimpleSwitchInput } from 'components/inputs/Switch';
import { formatDate, toastErrorMessage } from 'utils/helpers';
import { COUNT_TABLE_RECORDS } from 'constants/constants';
import AdminButton from './AdminButton';

const fields = (t) => [
  { key: 'name', label: t({ id: 'admins.name' }) },
  { key: 'email', label: t({ id: 'admins.email' }) },
  { key: 'lastLogin', label: t({ id: 'admins.lastLogin' }) },
  { key: 'active', label: t({ id: 'admins.active' }) },
  { key: 'actions', label: '' },
];

const AdminsTable = ({ admins, loading }) => {
  const { formatMessage: t } = useIntl();
  const changeUserAccountActivationStatus = useChangeUserAccountActivationStatus();
  const adminFields = useMemo(() => fields(t), [t]);

  return (
    <CDataTable
      fields={adminFields}
      hover
      items={admins}
      itemsPerPage={COUNT_TABLE_RECORDS}
      loading={loading}
      size="sm"
      scopedSlots={{
        name: ({ profile }) => <td>{`${profile.firstName} ${profile.lastName}`}</td>,
        email: ({ email }) => (
          <td>
            <a href={`mailto:${email}`}>{email}</a>
          </td>
        ),
        lastLogin: ({ lastLoginAt }) => <td>{lastLoginAt ? formatDate(lastLoginAt) : undefined}</td>,
        active: ({ deactivated, _id }) => (
          <td>
            <SimpleSwitchInput
              onChange={async () => {
                try {
                  await changeUserAccountActivationStatus({ deactivated, _id });
                } catch (error) {
                  toastErrorMessage({ error, t });
                }
              }}
              checked={!deactivated}
            />
          </td>
        ),
        actions: (user) => (
          <td>
            <AdminButton user={user} />
          </td>
        ),
      }}
    />
  );
};

export default AdminsTable;
