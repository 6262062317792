import React, { useMemo } from 'react';
import AdminsTable from 'components/admins/AdminsTable';
import 'components/common/AlertOverlay.scss';
import useGetAllAdmins from 'hooks/admins/useGetAllAdmins';
import AlertGraphQLError from 'components/common/AlertGraphQLError';
import useUser from 'hooks/useUser';
import { CCard, CCardBody, CCardHeader, CCol } from '@coreui/react';
import AdminButton from 'components/admins/AdminButton';
import { useIntl } from 'react-intl';
import s from './AdminsPage.module.scss';

const AdminsPage = () => {
  const { formatMessage: t } = useIntl();
  const [data, adminsLoading, adminsError] = useGetAllAdmins();
  const [me, meLoading, meError] = useUser();

  const admins = useMemo(() => {
    if (!me) return [];
    return data.map(({ _id, emails, profile, deactivated, lastLoginAt }) => ({
      _id,
      isMe: me?._id === _id,
      deactivated,
      profile,
      lastLoginAt,
      email: emails[0]?.address ?? '',
    }));
  }, [data, me]);

  return (
    <CCol>
      <CCard>
        <CCardHeader className={s.headerWrapper}>
          <h4>{t({ id: 'admins.title' })}</h4>
          <AdminButton>{t({ id: 'admins.add_admin_btn' })}</AdminButton>
        </CCardHeader>

        <CCardBody>
          {adminsError || meError ? (
            <AlertGraphQLError error={adminsError || meError} />
          ) : (
            <AdminsTable admins={admins} loading={adminsLoading || meLoading} />
          )}
        </CCardBody>
      </CCard>
    </CCol>
  );
};

export default AdminsPage;
