import gql from 'graphql-tag';
import get from 'lodash/get';
import { useQuery } from '@apollo/react-hooks';

export const GET_ADMINS = gql`
  query getAllAdmins {
    allAdmins {
      _id
      lastLoginAt
      deactivated
      roles
      emails {
        address
        verified
      }
      profile {
        firstName
        lastName
      }
    }
  }
`;

const useGetAllAdmins = () => {
  const { data, loading, error } = useQuery(GET_ADMINS);

  const admins = get(data, 'allAdmins', []);

  return [admins, loading, error];
};

export default useGetAllAdmins;
