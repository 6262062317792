import { CDataTable } from '@coreui/react';
import Pagination from 'components/common/Pagination';
import { COUNT_TABLE_RECORDS } from 'constants/constants';
import React, { useMemo } from 'react';
import { useIntl } from 'react-intl';
import { value2LocaleValue } from 'utils/helpers';

const energyRenovations = [
  'heating_system',
  'insulation_basement_ceiling',
  'insulation_facade',
  'insulation_top_ceiling',
  'new_windows',
  'solar_power_system',
  'controlled_living_space_ventilation',
];

const EnergyGhgBalance = ({ user_input, calculated_value }) => {
  const { formatMessage: t } = useIntl();
  const isUserInput = Number.isFinite(user_input);
  return (
    <td>
      {value2LocaleValue(isUserInput ? user_input : calculated_value)}{' '}
      {isUserInput ? <span style={{ whiteSpace: 'nowrap' }}>({t({ id: 'co2_report.user_input' })})</span> : null}
    </td>
  );
};

const CO2SavingsByProjectsTable = ({ co2Calculations = [], total, currentPage, changePage, loading }) => {
  const { formatMessage: t } = useIntl();

  const tableHead = useMemo(
    () => [
      { key: 'name', label: t({ id: 'projects.name' }) },
      { key: 'energyGhgBalanceBefore', label: t({ id: 'co2_report.energy_ghg_balance_before' }) },
      { key: 'energyGhgBalanceAfter', label: t({ id: 'co2_report.energy_ghg_balance_after' }) },
      ...energyRenovations.map((key) => ({ key, label: t({ id: `co2_report.${key}` }) })),
    ],
    [t],
  );

  const items = useMemo(
    () =>
      co2Calculations.map(({ project, co2_emissions_after, energy_renovations, co2_emissions_before }) => {
        const { name } = project;
        const { energy_ghg_balance_per_year: energyGhgBalanceBefore } = co2_emissions_before.result;
        const { energy_ghg_balance_per_year: energyGhgBalanceAfter } = co2_emissions_after.result;

        const values = Object.entries(energy_renovations || {}).reduce((acc, [key, value]) => {
          const v = Number.isFinite(value?.co2_saving) ? value.co2_saving : '-';
          return { ...acc, [key]: v };
        }, {});

        return { name, energyGhgBalanceBefore, energyGhgBalanceAfter, ...values };
      }),
    [co2Calculations],
  );

  return (
    <>
      <CDataTable
        fields={tableHead}
        hover
        items={items}
        itemsPerPage={COUNT_TABLE_RECORDS}
        loading={loading}
        size="sm"
        scopedSlots={{
          energyGhgBalanceBefore: ({ energyGhgBalanceBefore }) => <EnergyGhgBalance {...energyGhgBalanceBefore} />,
          energyGhgBalanceAfter: ({ energyGhgBalanceAfter }) => <EnergyGhgBalance {...energyGhgBalanceAfter} />,
        }}
      />
      <Pagination {...{ changePage, currentPage, total }} />
    </>
  );
};

export default CO2SavingsByProjectsTable;
