import React from 'react';
import { useIntl } from 'react-intl';
import { formatGraphqlErrors } from 'utils/helpers';
import Alert from './Alert';

const AlertGraphQLError = ({ error }) => {
  const { formatMessage: t } = useIntl();

  return <Alert color="danger">{formatGraphqlErrors({ error, t }).message}</Alert>;
};

export default AlertGraphQLError;
