import { useCallback } from 'react';
import gql from 'graphql-tag';
import { useMutation } from '@apollo/react-hooks';
import { GET_ADMINS } from './useGetAllAdmins';

const UPDATE_USER_MUTATION = gql`
  mutation updateUser($_id: ID!, $profile: UserProfileInput!) {
    updateUser(_id: $_id, profile: $profile) {
      _id
      profile {
        firstName
        lastName
      }
    }
  }
`;

const useUpdateUser = () => {
  const [updateUserMutation] = useMutation(UPDATE_USER_MUTATION);

  return useCallback(
    async ({ _id, firstName, lastName }) => {
      await updateUserMutation({
        variables: {
          _id,
          profile: {
            firstName,
            lastName,
          },
        },
        refetchQueries: [{ query: GET_ADMINS }],
        awaitRefetchQueries: true,
      });
    },
    [updateUserMutation],
  );
};

export default useUpdateUser;
