/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import Switch from 'react-switch';
import classnames from 'classnames';
import { spliceLabelOnMobile } from '../../utils/helpers';
import theme from '../../config/theme';
import styles from './Switch.module.scss';

export const SimpleSwitchInput = ({ onChange, checked, ...props }) => (
  <Switch {...props} checked={!!checked} onChange={onChange} onColor={theme.primaryColor} height={20} width={40} />
);

const SwitchInput = ({
  field, // { name, value, onChange, onBlur }
  form: { touched, errors, setFieldValue }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
  ...props
}) => {
  const { label, style } = props;
  const handleChange = React.useCallback(
    (value) => {
      setFieldValue(field.name, value);
    },
    [field.name, setFieldValue],
  );
  const hasError = touched[field.name] && errors[field.name];
  return (
    <div className="field-wrapper" style={style}>
      <label className={styles.label} htmlFor={field.name}>
        {spliceLabelOnMobile(label)}
      </label>
      <SimpleSwitchInput
        {...field}
        onChange={handleChange}
        checked={field.value}
        className={classnames({ 'error-input': hasError }, styles.switch)}
      />
      {hasError && <div className="error-text">{errors[field.name]}</div>}
    </div>
  );
};

export default SwitchInput;
