export default {
  isDevelopment: process.env.NODE_ENV !== 'production',
  isProduction: process.env.NODE_ENV === 'production',
  appName: process.env.REACT_APP_NAME,
  publicURL: process.env.PUBLIC_URL,
  debug: process.env.REACT_APP_DEBUG ? JSON.parse(process.env.REACT_APP_DEBUG) : false,
  apiUrl: process.env.REACT_APP_API_URL,
  rootUrl: process.env.REACT_APP_ROOT_URL,
  defaultLocale: process.env.REACT_APP_DEFAULT_LOCALE,
  privacyPolicy: process.env.REACT_APP_PRIVACY_POLICY,
  graphqlServerUrl: process.env.REACT_APP_GRAPHQL_SERVER_URL,
  googleAnalytics: process.env.REACT_APP_GOOGLE_ANALYTICS,
  googleAuthenticatorPlayMarket: process.env.REACT_APP_PLAY_MARKET_GOOGLE_AUTHENTICATOR,
  googleAuthenticatorAppStore: process.env.REACT_APP_STORE_GOOGLE_AUTHENTICATOR,
  iframeUrl: process.env.REACT_APP_IFRAME_URL,
  xlsxProjectsReportUrl: '/api/projects-report',
  xlsxProjectFullDetails: '/api/project-full-details',
};
