import { set } from 'lodash';
import { useMemo } from 'react';
import { useLocation, useHistory } from 'react-router-dom';

const useGetSearchParams = (params) => {
  const location = useLocation();
  const history = useHistory();
  const searchParams = new URLSearchParams(location.search);

  const setSearchParams = (newParams) => {
    const newSearchParams = new URLSearchParams(location.search);
    Object.entries(newParams).forEach(([key, value]) => {
      newSearchParams.set(key, value);
    });
    history.replace({ pathname: location.pathname, search: newSearchParams.toString() });
  };

  const selectedParams = useMemo(() => {
    if (typeof params === 'string') {
      return { [params]: searchParams.get(params) || undefined };
    }

    return (params || []).reduce((acc, key) => {
      set(acc, key, searchParams.get(key) || undefined);
      return acc;
    }, {});
  }, [params, searchParams]);

  if (!params) return { searchParams: Object.fromEntries([...searchParams]), setSearchParams };

  return { searchParams: selectedParams, setSearchParams };
};

export default useGetSearchParams;
