import React from 'react';
import get from 'lodash/get';
import { CInput, CFormGroup, CInvalidFeedback, CInputGroup, CInputGroupPrepend, CInputGroupText } from '@coreui/react';
import CIcon from '@coreui/icons-react';

const AuthInput = ({ icon, label, type, field, form }) => {
  const { name, onBlur } = field;
  const { touched, errors, setFieldValue } = form;

  const getClassNameForInput = get(touched, name) && get(errors, name) ? 'form-control is-invalid' : 'form-control';

  const handleChange = React.useCallback(
    (e) => {
      setFieldValue(name, e.target.value);
    },
    [name, setFieldValue],
  );

  return (
    <CFormGroup>
      <CInputGroup className="mb-3">
        <CInputGroupPrepend>
          <CInputGroupText>
            <CIcon name={icon} />
          </CInputGroupText>
        </CInputGroupPrepend>
        <CInput
          {...field}
          type={type}
          onBlur={onBlur}
          onChange={handleChange}
          name={name}
          placeholder={label}
          className={getClassNameForInput}
        />
      </CInputGroup>
      {get(touched, name) && get(errors, name) && (
        <CInvalidFeedback className="help-block">{errors[name]}</CInvalidFeedback>
      )}
    </CFormGroup>
  );
};
export default AuthInput;
