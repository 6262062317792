import { useMemo } from 'react';
import gql from 'graphql-tag';
import { useQuery } from '@apollo/react-hooks';
import get from 'lodash/get';

export const CO2_SAVINGS_REPORT = gql`
  query totalCO2SavingsReport {
    totalCO2SavingsReport {
      new_windows {
        co2_savings
        projects_count
      }
      insulation_top_ceiling {
        co2_savings
        projects_count
      }
      insulation_basement_ceiling {
        co2_savings
        projects_count
      }
      insulation_facade {
        co2_savings
        projects_count
      }
      controlled_living_space_ventilation {
        co2_savings
        projects_count
      }
      heating_system {
        co2_savings
        projects_count
      }
      solar_power_system {
        co2_savings
        projects_count
      }
    }
  }
`;

const useTotalCO2SavingsReport = () => {
  const { data, loading, error } = useQuery(CO2_SAVINGS_REPORT);

  const totalCO2SavingsReport = useMemo(() => get(data, 'totalCO2SavingsReport', null), [data]);

  return { totalCO2SavingsReport, loading, error };
};

export default useTotalCO2SavingsReport;
