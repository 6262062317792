import gql from 'graphql-tag';
import get from 'lodash/get';
import { useQuery, useApolloClient } from '@apollo/react-hooks';
import Cookies from 'js-cookie';

export const CURRENT_USER_FRAGMENT = gql`
  fragment currentUser on User {
    _id
    emails {
      address
      verified
    }
    profile {
      firstName
      lastName
    }
    roles
    is2FAEnabled
    deactivated
  }
`;

export const ME_QUERY = gql`
  query me {
    me {
      ...currentUser
    }
  }
  ${CURRENT_USER_FRAGMENT}
`;

export default function useUser() {
  const { loading, error, data, refetch } = useQuery(ME_QUERY);
  const client = useApolloClient();

  const roles = get(data, 'me.roles', []);

  if ((data?.me && !roles.includes('admin')) || data?.me?.deactivated) {
    Cookies.remove('token');
    client.writeQuery({ query: ME_QUERY, data: { me: null } });
    client.clearStore();
  }

  return [data?.me, loading, error, refetch];
}
