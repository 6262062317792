import React, { useCallback } from 'react';
import { Formik, Form, Field } from 'formik';
import { FormattedMessage, useIntl } from 'react-intl';
import { CContainer, CRow, CCol, CCardGroup, CCard, CCardBody } from '@coreui/react';
import OtpInput from 'components/inputs/OtpInput';
import * as yup from 'yup';
import get from 'lodash/get';
import { useLocation, useHistory } from 'react-router-dom';
import TextInput from 'components/inputs/TextInput';
import { toast } from 'react-toastify';
import useLogin from 'hooks/auth/useLogin';
import useAuthFormSubmit from 'hooks/auth/useAuthFormSubmit';
import routePaths from 'router/route-paths';
import ErrorMessage from 'components/common/ErrorMessage';
import Button from 'components/common/Button';

const useOnSubmitHandler = () => {
  const login = useLogin();
  const handleSubmit = useAuthFormSubmit(login);
  const history = useHistory();

  const onSubmit = useCallback(
    async (values, formikActions) => {
      try {
        const data = await handleSubmit({ ...values, isAdmin: true }, formikActions);
        if (data) history.push(routePaths.default);
      } catch (error) {
        toast.error(error.message);
      }
    },
    [handleSubmit, history],
  );
  return onSubmit;
};

const schema = yup.object().shape({
  email: yup
    .string()
    .required(<FormattedMessage id="errors.email_required" />)
    .email(<FormattedMessage id="errors.email_invalid" />),
  plainPassword: yup.string().required(<FormattedMessage id="errors.password_required" />),
  otp: yup
    .string()
    .required(<FormattedMessage id="errors.fill_field" />)
    .typeError(<FormattedMessage id="errors.fill_field" />),
});

const LoginWithTwoFA = () => {
  const onSubmit = useOnSubmitHandler();
  const location = useLocation();
  const history = useHistory();
  const { formatMessage: __ } = useIntl();
  const email = get(location, 'state.user.email', null);
  const plainPassword = get(location, 'state.user.plainPassword', null);

  if (!email || !plainPassword) {
    history.replace(routePaths.login);
  }

  const onBack = useCallback(() => history.replace(routePaths.login), [history]);

  const initialValues = {
    otp: '',
    email,
    plainPassword,
  };

  return (
    <Formik initialValues={initialValues} validationSchema={schema} onSubmit={onSubmit}>
      {({ isSubmitting, errors }) => (
        <>
          <div className="c-app c-default-layout flex-row align-items-center">
            <CContainer>
              <CRow className="justify-content-center align-items-center">
                <CCol md="10" lg="8" xl="6">
                  <CCardGroup>
                    <CCard className="p-4">
                      <CCardBody>
                        <Form>
                          <h1>{__({ id: 'page_titles.login2FA' })}</h1>
                          <Field
                            disabled
                            component={TextInput}
                            type="email"
                            icon="cil-user"
                            name="email"
                            label={__({ id: 'auth.email' })}
                          />
                          <Field
                            component={TextInput}
                            disabled
                            type="password"
                            icon="cil-lock-locked"
                            name="plainPassword"
                            label={__({ id: 'auth.password' })}
                          />
                          <Field
                            name="otp"
                            component={OtpInput}
                            numInputs={6}
                            separator="-"
                            required
                            label={<FormattedMessage id="auth.verification_code_tip" />}
                          />
                          <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '20px' }}>
                            <Button onClick={onBack} disabled={isSubmitting} color="secondary" type="button">
                              {__({ id: 'buttons.back' })}
                            </Button>
                            <Button disabled={isSubmitting} loading={isSubmitting} type="submit">
                              {__({ id: 'buttons.submit' })}
                            </Button>
                          </div>
                        </Form>
                        <ErrorMessage message={errors.form} />
                      </CCardBody>
                    </CCard>
                  </CCardGroup>
                </CCol>
              </CRow>
            </CContainer>
          </div>
        </>
      )}
    </Formik>
  );
};

export default LoginWithTwoFA;
